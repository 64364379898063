import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { ClassicTable } from "../../utils/components/Styles";
import EditWrap from "../commonComponent/editWrap";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const gstTypes = [
  { value: 1, label: "State GST" },
  { value: 2, label: "Central Gst" },
  { value: 3, label: "Non Gst" },
];

const EditSupplierInvoices = () => {
  const { id } = useParams();
  const userID = localStorage.getItem("id");
  const [supplierInvoicesData, setSupplierInvoicesData] = useState({
    supplierName: null,
    Address: null,
    Contact: null,
    gstNumber: null,
    invoiceNumber: null,
    invoiceAmount: null,
    Sgst: null,
    Cgst: null,
    Igst: null,
    paymentStatus: null,
    Remarks: null,
    invoiceFile: null,
    invoiceDate: null,
    gstType: null,
    gstpercentages: null,
    paidAmount: null,
    paymentDate: null,
    paymentMode: null,
    narration: null,
  });
  const [editAccess, setEditAccess] = useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [contactError, setContactError] = useState("");
  const [paymentHistoryAllData, setpaymentHistoryAllData] = React.useState([]);
  const [gstPercentageOptions, setGstPercentageOptions] = React.useState([]);
const calculateTotalAmountWithGST = () => {
  const { invoiceAmount, gstType, gstpercentages } = supplierInvoicesData;

  if (!invoiceAmount || !gstType || !gstpercentages) {
    return invoiceAmount || 0;
  }
  let gstAmount = 0;
  // Calculate GST based on gstType
  if (gstType === 1) { // State GST and Central GST
    gstAmount = (invoiceAmount * gstpercentages) / 100;
  } else if (gstType === 2) { // IGST
    gstAmount = (invoiceAmount * gstpercentages) / 100;
  }
  return invoiceAmount + gstAmount;
};
const invoiceAmountWithGst = calculateTotalAmountWithGST();

  const validateData = () => {
    let isValid = true;

    if (!supplierInvoicesData?.supplierName) {
      toast.error("Supplier Name is required");
      isValid = false;
    }

    if (!supplierInvoicesData?.Address) {
      toast.error("Address is required");
      isValid = false;
    }

    if (!supplierInvoicesData?.invoiceDate) {
      toast.error("Invoice Date is required");
      isValid = false;
    }

    return isValid;
  };

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `supplierPaymentMaster/${id}`)
      .then((res) => {
        toast("supplierPaymentMaster was deleted!");
        setTimeout(() => window.location.reload(), 1000);
      })
      .catch((error) => {
        console.error(
          "There was an error delte the payment history data!",
          error
        );
      });
  };

  const fetchSettings = () => {
    axiosWithToken.get(BASE_URL + `settings`).then((res) => {
      res.data.map((setting) => {
        if (setting.keyName === "gstPercentages") {
          let newKeys = setting.value.split(",");
          const arrayOfObjects = newKeys.map((value) => ({
            value: parseFloat(value),
            label: value,
          }));
          setGstPercentageOptions(arrayOfObjects);
        }
      });
    });
  };
  
  const fetchPaymentHistory = () => {
    axiosWithToken
      .post(BASE_URL + `dynamic`, {
        query: `select * from supplierPaymentMaster where supplierInvoiceId =${id} order by id desc`,
      })
      .then((response) => {
        const totalPaidAmount = response?.data?.reduce(
          (sum, payment) => sum + parseFloat(payment?.paidAmount || 0),
          0
        );
        setTotalAmount(totalPaidAmount);
        setpaymentHistoryAllData(response.data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the payment history data!",
          error
        );
      });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);

    axiosWithToken
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then((res) => {
        if (res.status === 200) {
          setSupplierInvoicesData({
            ...supplierInvoicesData,
            invoiceFile: res.data,
          });
          toast.success("File Uploaded Successfully!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleChange = (e, newValue, field) => {
    if (e) {
      const { name, value } = e.target;
      setSupplierInvoicesData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (newValue !== undefined && field) {
      setSupplierInvoicesData((prevData) => ({
        ...prevData,
        [field]: newValue,
      }));
    }
  };

  const handlePaymentSubmit = () => {
    const paymentPayload = {
      supplierInvoiceId: id,
      paymentDate: supplierInvoicesData?.paymentDate
        ? moment(supplierInvoicesData?.paymentDate).format("YYYY-MM-DD")
        : null,
      paymentMode: supplierInvoicesData?.paymentMode || null,
      paidAmount: supplierInvoicesData?.paidAmount || null,
      narration: supplierInvoicesData?.narration || null,
      paidAmount: supplierInvoicesData?.paidAmount || 0,
    };

    axiosWithToken
      .post(BASE_URL + `supplierPaymentMaster`, paymentPayload)
      .then((res) => console.log(res))
      .catch((error) =>
        console.error("There was an error submitting the payment data!", error)
      );
  };

  const handleSubmit = () => {
    if (!validateData()) return;

    if (invoiceAmountWithGst < Number(supplierInvoicesData.paidAmount) + totalAmount) {
      toast.error("Payment Amount is higher than Due Amount!!");
      return;
    }

    const newPaidAmount = parseFloat(supplierInvoicesData?.paidAmount || 0);
    const updatedTotalPaid = totalAmount + newPaidAmount;
    const paymentStatus = updatedTotalPaid >= supplierInvoicesData?.invoiceAmount ? 1 : 0;    

    const payload = {
      supplierName: supplierInvoicesData?.supplierName || null,
      Address: supplierInvoicesData?.Address || null,
      Contact: supplierInvoicesData?.Contact || null,
      gstNumber: supplierInvoicesData?.gstNumber || null,
      invoiceNumber: supplierInvoicesData?.invoiceNumber || null,
      invoiceAmount: supplierInvoicesData?.invoiceAmount || null,
      gstType: supplierInvoicesData?.gstType || null,
      gstpercentages: supplierInvoicesData?.gstpercentages || null,
      paymentStatus: paymentStatus || null,
      Remarks: supplierInvoicesData?.Remarks || null,
      invoiceFile: supplierInvoicesData?.invoiceFile || null,
      createdBy: userID,
      invoiceDate: supplierInvoicesData?.invoiceDate
        ? moment(supplierInvoicesData?.invoiceDate).format("YYYY-MM-DD")
        : null,
    };
    // console.log(payload);
    const request =
      id === "0"
        ? axiosWithToken.post(BASE_URL + `supplierInvoices`, payload)
        : axiosWithToken.patch(BASE_URL + `supplierInvoices/${id}`, payload);

    request
      .then(() => {
        toast.success(
          id === "0"
            ? "supplier Invoice created successfully!"
            : "supplier Invoice updated successfully!"
        );
        if (supplierInvoicesData?.paidAmount) {
          handlePaymentSubmit();
        }
        setTimeout(() => window.location.reload(), 1000);
      })
      .catch((error) => {
        console.error("There was an error submitting the form!", error);
      });
  };

  useEffect(() => {
    if (id !== "0") {
      axiosWithToken
        .get(BASE_URL + `supplierInvoices/${id}`)
        .then((response) => {
          setSupplierInvoicesData(response.data[0]);
        })
        .catch((error) => {
          console.error("There was an error fetching the invoice data!", error);
        });

      fetchPaymentHistory();
    }
    fetchSettings();
  }, [id]);

  return (
    <EditWrap>
      <Box>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
      {id == "0" ? "Create" :"Edit"} Supplier Invoice
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextField
              label="Supplier Name *"
              size="small"
              name="supplierName"
              value={supplierInvoicesData?.supplierName || ""}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextField
              label="Address *"
              size="small"
              name="Address"
              value={supplierInvoicesData?.Address || ""}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextField
              label="Contact"
              size="small"
              name="Contact"
              type="number"
              value={supplierInvoicesData?.Contact || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 10) {
                  setContactError("Contact number should be 10 digits only");
                } else {
                  setContactError("");
                  handleChange(e);
                }
              }}
              fullWidth
              inputProps={{
                autoComplete: "off",
              }}
              error={contactError !== ""}
              helperText={contactError}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextField
              label="Gst Number"
              size="small"
              name="gstNumber"
              value={supplierInvoicesData?.gstNumber || ""}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="Invoice Date *"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={
                  supplierInvoicesData?.invoiceDate
                    ? new Date(supplierInvoicesData?.invoiceDate)
                    : ""
                }
                onChange={(newValue) => handleChange(null, newValue, "invoiceDate")}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextField
              label="Invoice Number"
              size="small"
              name="invoiceNumber"
              value={supplierInvoicesData?.invoiceNumber || ""}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextField
              label="Invoice Amount"
              size="small"
              name="invoiceAmount"
              type="number"
              value={supplierInvoicesData?.invoiceAmount || ""}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              disabled={id != "0"}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Autocomplete
              size="small"
              value={
                gstTypes.find(
                  (option) => option.value === supplierInvoicesData?.gstType
                ) || null
              }
              options={gstTypes}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label="GST Type *" />
              )}
              onChange={(event, newValue) =>
                handleChange(null, newValue?.value, "gstType")
              }
           disabled={id != "0"}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Autocomplete
              size="small"
              value={
                gstPercentageOptions.find(
                  (option) =>
                    option.value === supplierInvoicesData?.gstpercentages
                ) || null
              }
              options={gstPercentageOptions}
              getOptionLabel={(option) => `${option.label} %`}
              renderInput={(params) => (
                <TextField {...params} label="GST Percentage" />
              )}
              onChange={(event, newValue) =>
                handleChange(null, newValue?.value, "gstpercentages")
              }
              disabled={id != "0"}
            />
          </Grid>

          {supplierInvoicesData?.gstType == 1 && (
            <>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <TextField
                  label="Sgst"
                  size="small"
                  name="Sgst"
                  type="number"
                  value={(
                    (supplierInvoicesData?.invoiceAmount *
                      (supplierInvoicesData?.gstpercentages
                        ? supplierInvoicesData?.gstpercentages / 2
                        : 9)) /
                    100
                  ).toFixed(2)}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={3}>
                <TextField
                  label="Cgst"
                  size="small"
                  type="number"
                  name="Cgst"
                  value={(
                    (supplierInvoicesData?.invoiceAmount *
                      (supplierInvoicesData?.gstpercentages
                        ? supplierInvoicesData?.gstpercentages / 2
                        : 9)) /
                    100
                  ).toFixed(2)}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  disabled
                />
              </Grid>
            </>
          )}

          {supplierInvoicesData?.gstType == 2 && (
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <TextField
                label="Igst"
                size="small"
                name="Igst"
                type="number"
                value={(
                  (supplierInvoicesData?.invoiceAmount *
                    (supplierInvoicesData?.gstpercentages
                      ? supplierInvoicesData?.gstpercentages
                      : 18)) /
                  100
                ).toFixed(2)}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{ width: "100%", m: "auto" }}
            >
              {supplierInvoicesData?.invoiceFile == null ||
              supplierInvoicesData?.invoiceFile == ""
                ? "Upload file"
                : supplierInvoicesData?.invoiceFile.split("_").pop()}
              <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextField
              label="Remarks"
              size="small"
              name="Remarks"
              value={supplierInvoicesData?.Remarks || ""}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>

        <br />
        <br />

        {id != "0" && (
        <div>
          <Typography variant="h6" component="div" sx={{ mb: 2 }}>
            Payment History
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <TextField
                label="Paid Amount"
                size="small"
                name="paidAmount"
                value={supplierInvoicesData?.paidAmount || ""}
                // onChange={handleChange}
                onChange={(e) => {
                  const paidAmount = parseFloat(e.target.value);
                  if (invoiceAmountWithGst < paidAmount + totalAmount) {
                    toast.error("Payment Amount is higher than Due Amount!!");
                  }
                  handleChange(e);
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="Payment Date"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  onChange={(newValue) => handleChange(null, newValue, "paymentDate")}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={[
                  { id: 1, label: "Cash" },
                  { id: 2, label: "Cheque" },
                  { id: 3, label: "NEFT" },
                  { id: 4, label: "UPI" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Payment Mode" />
                )}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  handleChange(null, newValue?.label, "paymentMode");
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={3}>
              <TextField
                label="Narration"
                size="small"
                name="narration"
                value={supplierInvoicesData?.narration || ""}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>

          <br />
          <br />

          <ClassicTable>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>Payment Date</TableCell>
                  <TableCell>Paid Amount</TableCell>
                  <TableCell>Payment Mode</TableCell>
                  <TableCell>Narration</TableCell>
                  <TableCell>Due Amount</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentHistoryAllData.map((row, index) => {
                  const totalPaid = paymentHistoryAllData
                    .slice(index)
                    .reduce(
                      (sum, payment) =>
                        sum + parseFloat(payment.paidAmount || 0),
                      0
                    );

                  const dueAmount =
                    parseFloat(invoiceAmountWithGst || 0) -
                    totalPaid;

                  return (
                    <TableRow key={row.name}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {row.paymentDate
                          ? moment(row.paymentDate).format("DD-MM-YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>{row.paidAmount}/-</TableCell>
                      <TableCell>{row.paymentMode}</TableCell>
                      <TableCell>{row.narration}</TableCell>
                      <TableCell
                        style={{ color: dueAmount > 0 ? "red" : "green" }}
                      >
                        {dueAmount.toFixed(2)}/-
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Really want to delete payment history?"
                              )
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color: "red" }} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </ClassicTable>
        </div>
        )}

        <br/> 
        <br/>

        <Toolbar
          style={{
            padding: "0px",
            overflow: "auto",
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <Button
            variant="contained"
            style={{ backgroundColor: "grey" }}
            size="small"
            onClick={() => {
              if (window.confirm("Are you sure you want to cancel?")) {
                window.history.back();
              }
            }}
          >
            Cancel
          </Button>
          <Tooltip
            title={
              !(editAccess?.includes(1) || editAccess?.includes(0))
                ? "You don't have access"
                : ""
            }
          >
            <Button
              variant="contained"
              size="small"
              disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
              sx={{ m: 0 }}
              onClick={() => {
                if (!validateData()) return;
                handleSubmit();
              }}
            >
              {id == "0" ? "save" :" update"}
            </Button>
          </Tooltip>
        </Toolbar>
      </Box>
    </EditWrap>
  );
};

export default EditSupplierInvoices;

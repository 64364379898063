import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import moment from "moment";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../global";
import SendTimeExtensionIcon from "@mui/icons-material/SendTimeExtension";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  TextField,
  Toolbar,
  Autocomplete,
  Grid,
  useMediaQuery,
  Modal,
  Checkbox,
} from "@mui/material";
import ExcelDownload from "../../../utils/components/excelDownload";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { MastersEQPListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
import ListWrap from "../../commonComponent/listWrap";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");


const EQUIPMENT_TYPE={
  1: "Master",
  2: "Supportive",
}

const columns = [
  { id: "id", label: "Sr. No." },
  { id: "dName", label: "Disipline" },
  { id: "stIds", label: "Id", minWidth: 40 },
  { id: "serialNo", label: "Serial Number", minWidth: 40 },
  { id: "standardName", label: "Master EQP Name", minWidth: 40 },
  { id: "make", label: "Make", minWidth: 40 },
  { id: "model", label: "Model", minWidth: 40 },
  {
    id: "certificateNumber",
    label: "Tracability",
    align: "left",
    minWidth: 40,
  },
  {
    id: "traceability",
    label: "Cal Agency",
    align: "left",
    minWidth: 40,
  },
  {
    id: "type",
    label: "Type",
    align: "left",
    minWidth: 60,
  },
  {
    id: "calDate",
    label: "Cal.Date",
    minWidth: 40,
    align: "left",
    format: (value) => moment(value).format("DD-MM-YYYY"),
  },
  {
    id: "validUpto",
    label: "Due Date",
    minWidth: 40,
    align: "left",
    format: (value) => moment(value).format("DD-MM-YYYY"),
  },
];
const notNeededColumn = ["id", "status", "type"];
export default function StandardsList(props) {
  const dMastersEQPListGuides = MastersEQPListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [standardList, setStandardList] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Master Id");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [from, setFrom] = React.useState(null);
  const [finalData, setFinalData] = React.useState([]);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searched, setSearched] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  const handleSelectRow = (id) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };
  const isSelected = (id) => selected.includes(id);
  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const submitSiteDelete = (id) => {
    const idString = (Array.isArray(id) ? id : [id]).join(',');
    let standardsQuery = {
      query: `DELETE FROM standards WHERE id IN (${idString});`
    }; 
    let standardRangesQuery = {
      query: `DELETE FROM standardRanges WHERE standardId IN (${idString});`
    };
    let url = BASE_URL;
    axiosWithToken
      .post(url + `dynamic`, standardsQuery)
      .then(() => {
        return axiosWithToken.post(url + `dynamic`, standardRangesQuery);
      })    
      .then((res) => {
        toast("Master EQP deleted successfully!");
        setTimeout(refresh, 500);
      })
      .catch((error) => console.log("err",error));
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Disipline: "disc.name",
        "Master Id": "standard.stId",
        "Master EQP Name": "standard.standardName",
        Tracability: "standard.certificateNumber",
        Type: "standard.type",
        Due: "standard.validUpto",
      }[searchBy];

      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }
      whereConditions += ` standard.calDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}'`;
    }
    if (whereConditions == "") {
      whereConditions += " where standard.status != -1  ";
    } else {
      whereConditions += " and standard.status = 1  ";
    }

    if (searchBy === "Due") {
      whereConditions += " and validUpto < CURDATE()";
    }

    if(props.isCount == true){
      whereConditions +=
      whereConditions.length > 0
        ? ` and validUpto is NOT NULL and validUpto <= CURDATE() + INTERVAL 20 DAY`
        : `where validUpto is NOT NULL and validUpto <= CURDATE() + INTERVAL 20 DAY`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `select disc.name as dName, standard.stId as stIds,standard.serialNo, standard.standardName,standard.make,standard.model,standard.certificateNumber,standard.traceability,standard.type,standard.validUpto,standard.calDate,   standard.status,standard.type,standard.id from standards as standard left join discipline as disc on standard.disciplineId = disc.id ${whereConditions} order by standard.id DESC ${pagination_settings}`,
    };
    if (count)
      data.query = `select count(*) as no_of_rows from standards as standard left join discipline as disc on standard.disciplineId = disc.id ${whereConditions} order by standard.id DESC `;

    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);
    axiosWithToken
    .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getTotalRows" ,"StandardsList")}><u>Error: {err.message}</u></h6>);
        }
      });
  }

  function fetchStandards() {
    let data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].validUpto && res.data[i].validUpto !== null) {
            moment(res.data[i].validUpto).format("DD-MM-YYYY");
          }
          res.data[i].type = EQUIPMENT_TYPE[Number(res.data[i].type)];
        }
        setStandardList([...res.data]);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchStandards" ,"StandardsList")}><u>Error: {err.message}</u></h6>);
        }
      });
  }

  const copyEquipment = async (id) => {
    let instrument = await axiosWithToken
      .get(BASE_URL + `standards/${id}`)
      .then((res) => res.data[0] || []);
    delete instrument.id;
    delete instrument.lastModified;
    delete instrument.validUpto;
    let standardRangesData = await axiosWithToken
      .get(
        BASE_URL +
          `standardRanges?_where=(standardId,eq,${id})~and(status,eq,1)`
      )
      .then((res) => res.data || []);
    axiosWithToken.post(BASE_URL + "standards", instrument).then((res) => {
      standardRangesData.map((ele) => {
        delete ele.id;
        delete ele.lastModified;
        ele.standardId = res.data.insertId;
      });
      axiosWithToken
        .post(BASE_URL + "standardRanges/bulk", standardRangesData)
        .then((res2) => setTimeout(refresh, 500));
    });
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname); 
    setSearched(true);
    fetchStandards();
    getTotalRows();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Master Id");
    setSearchKey("");
    setFrom(null);
    setTo(null);
    setSearched(false); 
    navigate("/master/masterEQP");
  };

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 0px" }}
    >
      <Grid item xs={6} sm={3} md={1} lg={1.5}>
        <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
          <DatePicker
           className="Listinputcss"
            slotProps={{ textField: { size: "small", fullWidth: true } }}
            label="From"
            value={from ? new Date(from) : ""}
            inputFormat="dd/MM/yyyy"
            format="dd/MM/yyyy"
            onChange={(newValue) => {
              setFrom(newValue);
              setSearched(false);
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={3} md={1} lg={1.5}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
           className="Listinputcss"
            slotProps={{ textField: { size: "small", fullWidth: true } }}
            label="To"
            value={to ? new Date(to) : ""}
            inputFormat="dd/MM/yyyy"
            format="dd/MM/yyyy"
            onChange={(newValue) => {
              setTo(newValue);
              setSearched(false);
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
            style={{ marginRight: "20px" }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
           className="Listinputcss"
          size="small"
          value={searchBy}
          id="stdMastersEQPList_searchby"
          options={[
            { key: "disipline", label: "Disipline" },
            { key: "standardId", label: "Master Id" },
            { key: "standardName	", label: "Master EQP Name" },
            { key: "certificateNumber	", label: "Tracability" },
            { key: "standard.type	", label: "Type" },
            { key: "due", label: "Due" }, 
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      {searchBy != "Due" && (
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <TextField
         className="Listinputcss"
          style={{ width: "100%" }}
          id="stdMastersEQPList_searchbytext"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          inputProps={{
            autoComplete: 'off',
          }}
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value)
            setSearched(false);
          }}
        />
      </Grid>
      )}
      <Grid item xs={12} sm={6} md={1} lg={1} textAlign={"left"}>
        <Button
          id="stdMastersEQPList_searchbtn"
          variant="contained"
          size="small"
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
            handleFilterClose();
          }}
        >
         {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
      {!isMobile && (
        <Grid item xs={6} sm={6} md={2} lg={2} textAlign={"left"}>
          <ExcelDownload
            finalData={standardList}
            notNeededColumn={notNeededColumn}
          />
        </Grid>
      )}
    </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  useEffect(() => {
    if (searched==false) {
      getTotalRows();
    fetchStandards();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);
  
  useEffect(() => {
      getTotalRows();
    fetchStandards();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (props.isCount === true) {
      getTotalRows();
      fetchStandards();
    }
  }, [props.isCount]);

  return (
    <ListWrap>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          <Button
            id="stdMastersEQPList_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/master/masterEQP/editStandard/0"
          >
            <b>Create Master EQP</b>
          </Button>
        </Toolbar>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                dMastersEQPListGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </div>
      </div>
      {!isMobile && filterSection()}
      {selected.length > 0 && (
        <Box sx={{textAlign:"left", margin:"10px 0px"}}>
          <Button
          variant="contained"
          size="small"
          color={"error"}
          onClick={() => {
            if (window.confirm("Really want to delete Master EQP?")){
              submitSiteDelete(selected);
            } 
          }}
          >Multiple Delete</Button>
        </Box>
      )}
      <TableContainer sx={{ maxHeight: isMobile ? "75vh" : "65vh" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="stdMastersEQPList_table"
          sx={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <TableRow>
              {(editAccess?.includes(2) || editAccess?.includes(0)) && (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < standardList.length}
                    checked={selected.length === standardList.length}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelected(standardList.map((row) => row.id));
                      } else {
                        setSelected([]);
                      }
                    }}
                  />
            </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {standardList?.length > 0 ? (
              standardList.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {(editAccess?.includes(2) || editAccess?.includes(0)) && (
                     <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected(row.id)}
                      onChange={() => handleSelectRow(row.id)}
                    />
                  </TableCell>
                    )}
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id == "id") {
                        value = page * rowsPerPage + (index + 1);
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                           {["stIds", "standardName"].includes(column.id) ? (
                              <Link to={`/master/masterEQP/editStandard/${row.id}`} className="custom-link" title="Edit Master EQP"
                              style={{ color: column.id == "standardName" && moment(row.validUpto).isBefore(moment().subtract(1, 'days')) ? 'red' : 'inherit'}}>
                                {value}
                              </Link>
                            ) : column.format ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="Edit Master EQP" placement="top-start">
                          <Button
                            id="stdMastersEQPList_edit"
                            component={Link}
                            to={`/master/masterEQP/editStandard/${row.id}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Copy Master EQP" placement="top-start">
                          <Button
                            id="stdMastersEQPList_copy"
                            disabled={!(editAccess?.includes(5) || editAccess?.includes(0))}
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Do want to copy and create new Master EQP using selected Master EQP?"
                                )
                              ) {
                                copyEquipment(row.id);
                              }
                            }}
                          >
                            
                            <ContentCopyIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title="Delete Master EQP"
                          placement="top-start"
                        >
                          <Button
                            id="stdMastersEQPList_delete"
                            disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Really want to delete Master EQP?"
                                )
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon  style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                 <h6><b>Data Not Found</b></h6>
                </TableCell>
              </TableRow>
            )}              
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 30, 50]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </ListWrap>
  );
}

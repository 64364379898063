import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Grid, TextField, Autocomplete, Checkbox } from "@mui/material";
import { BASE_URL } from "./../../global";
import ExcelDownload from "../../utils/components/excelDownload";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ListWrap from "../commonComponent/listWrap";

let notNeededColumn = ["id"];

const headers = [
  { key: "companyName", label: "Client" },
  { key: "serviceReqNumber", label: "SRF No." },
  { key: "Total_Instrument", label: "Total Instrument" },
  { key: "Calibrated", label: "Calibrated" },
  { key: "Approved", label: "Approved" },
  { key: "Pending", label: "Pending" },
  { key: "invoiceStatus", label: "Invoice Status" },
];
export default function ProgressReport() {
  const [page, setPage] = React.useState(0);
  const [srfNo, setSrfNo] = React.useState(0);
  const [searchSRNO, setsearchSRNO] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [statusData, setStatusData] = React.useState([]);
  const [selectedSRFNo, setSelectedSRFNo] = React.useState([]);
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const printComponentRef = React.useRef();
  const [searched, setSearched] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getSRFNOList = (event) => {
    let url = BASE_URL;
    let sqlQuery = {
      query: `SELECT * FROM srfs WHERE status = 1 ${
        fromDate && toDate
          ? `AND DATE(entryDate) BETWEEN '${fromDate}' AND '${toDate}'`
          : ""
      } ORDER BY id DESC`,
    };

    axiosWithToken
      .post(url + `dynamic`, sqlQuery)
      .then((res) => {
        setSrfNo(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var pushArray = [];

  for (let i = 0; i < srfNo.length; i++) {
    pushArray.push({
      id: srfNo[i].id,
      label: srfNo[i].serviceReqNumber,
    });
  }

  const getData = (event) => {
    let url = BASE_URL;
    const query = {
      query: `SELECT cv.companyName, cv.serviceReqNumber AS serviceReqNumber, COUNT(*) AS Total_Instrument, COUNT(CASE WHEN cv.calibratedby IS NOT NULL THEN 1 END) AS Calibrated, COUNT(CASE WHEN cv.approvedby IS NOT NULL THEN 1 END) AS Approved, COUNT(CASE WHEN cv.calibratedby IS NULL THEN 1 END) AS Pending, CASE WHEN ci.serviceReqNumber IS NOT NULL THEN 'Yes' ELSE 'No' END AS invoiceStatus FROM certificatesView cv  LEFT JOIN customInvoice ci ON ci.serviceReqNumber LIKE CONCAT('%', cv.serviceReqNumber, '%') WHERE cv.serviceReqNumber IN (${searchSRNO
        .map((srno) => `'${srno}'`)
        .join(
          ","
        )}) GROUP BY cv.companyName, cv.serviceReqNumber, invoiceStatus;`,
    };

    axiosWithToken
      .post(url + `dynamic`, query)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const findStatusBysrfNumber = (event) => {
    let url = BASE_URL;
    const query = { query: `SELECT c.companyName as Client_Name, s.serviceReqNumber as SRF_Number, CASE WHEN si.requestedDucName IS NOT NULL AND si.requestedDucName != '' THEN si.requestedDucName ELSE i.instrumentName END as Instrument_Name, si.DUCID, si.serialNumber as Serial_ID, CASE WHEN sa.progressPercent >= 25 THEN 'Yes' ELSE 'No' END AS Inward_Status, CASE WHEN sa.progressPercent >= 50 THEN 'Yes' ELSE 'No' END AS Datasheet_Status, CASE WHEN sa.progressPercent >= 75 THEN 'Yes' ELSE 'No' END AS Certificate_Status, CASE WHEN sa.progressPercent >= 90 THEN 'Yes' ELSE 'No' END AS Dispatch_Status FROM srfs s LEFT JOIN clients c ON s.clientId = c.id LEFT JOIN srfInstruments si ON s.id = si.srfsId LEFT JOIN instruments i ON si.instrumentId = i.id LEFT JOIN srfAnalytics sa ON s.id = sa.srfId AND si.id = sa.srfInstrumentId WHERE s.serviceReqNumber IN (${searchSRNO?.map((srno) => `'${srno}'`)?.join(",")})` };


    axiosWithToken
      .post(url + `dynamic`, query)
      .then((res) => {
        setStatusData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const search = () => {
    setSearched(true);
    getData();
    findStatusBysrfNumber();
  };
  const clearSearch = () => {
    setSearched(false);
    setSelectedSRFNo([]);
    setFromDate("");
    setToDate("");
    setData([]);
  };

  useEffect(() => {
    getSRFNOList();
  }, [toDate]);


  return (
    <ListWrap>
      <Grid
        container
        spacing={1}
        justifyContent={"start"}
        alignItems={"center"}
      >
        <Grid item xs={6} sm={3} md={1} lg={1.5} textAlign={"left"}>
          <TextField
            id="Start Date"
            label="Date From"
            variant="outlined"
            type="date"
            className="Listinputcss"
            required={true}
            InputLabelProps={{ shrink: true }}
            size="small"
            value={fromDate}
            onChange={(e) => {
              setFromDate(e.target.value);
              setSearched(false);
            }}
          />
        </Grid>

        <Grid item xs={6} sm={3} md={1} lg={1.5} textAlign={"left"}>
          <TextField
            id="Start Date"
            label="Date To"
            variant="outlined"
            type="date"
            className="Listinputcss"
            required={true}
            InputLabelProps={{ shrink: true }}
            size="small"
            value={toDate}
            onChange={(e) => {
              setToDate(e.target.value);
              setSearched(false);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} textAlign={"left"}>
          <Autocomplete
            size="small"
            className="Listinputcss"
            id="combo-box-demo"
            options={[{ id: "selectAll", label: "Select All" }, ...pushArray]} // Add "Select All" option
            disableCloseOnSelect
            multiple
            limitTags={3}
            value={pushArray.filter((option) =>
              selectedSRFNo.includes(option.id)
            )}
            onChange={(event, value) => {
              const selectedIds = value.map((e) => e.id);

              if (selectedIds.includes("selectAll")) {
                // If "Select All" is selected, select all options
                if (selectedSRFNo.length === pushArray.length) {
                  // If all are selected, remove "Select All"
                  setSelectedSRFNo([]);
                  setsearchSRNO([]);
                } else {
                  // Select all options
                  setSelectedSRFNo(pushArray.map((e) => e.id));
                  setsearchSRNO(pushArray.map((e) => e.label));
                }
              } else {
                const selectedLabels = value.map((e) => e.label);
                setSelectedSRFNo(selectedIds);
                setsearchSRNO(selectedLabels);
              }
              setSearched(false);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={
                    option.id === "selectAll"
                      ? selectedSRFNo.length === pushArray.length
                      : selectedSRFNo.includes(option.id)
                  }
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Select SRF No" />
            )}
          />
        </Grid>

        <Grid item xs={3} sm={3} md={3} lg={1} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            className="Listinputcss"
            color={searched ? "error" : "primary"}
            sx={{ m: 0 }}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
          >
            {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
        </Grid>
        {data.length > 0 && (
          <>
            <Grid item xs={4} sm={3} md={3} lg={2} textAlign={"left"}>
              <ExcelDownload
                finalData={data}
                notNeededColumn={notNeededColumn}
                headers={headers}
                secondTable={statusData}
              />
            </Grid>
          </>
        )}
      </Grid>

      <TableContainer>
        <div style={{ width: "100%", margin: "auto" }} ref={printComponentRef}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            style={{ borderCollapse: "collapse", marginTop: "5px" }}
          >
            <TableBody className="single-border-table">
              <TableRow>
                <TableCell>
                  <b>Sr.No.</b>
                </TableCell>
                <TableCell>
                  <b>Client</b>
                </TableCell>
                <TableCell>
                  <b>SRF No.</b>
                </TableCell>
                <TableCell>
                  <b>Total Instrument</b>
                </TableCell>
                <TableCell>
                  <b>Calibrated</b>
                </TableCell>
                <TableCell>
                  <b>Approved</b>
                </TableCell>
                <TableCell>
                  <b>Pending</b>
                </TableCell>
                <TableCell>
                  <b>Invoice</b>
                </TableCell>
              </TableRow>
              {data?.length > 0 ? (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        id="pagbreaksborder"
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        style={{ pageBreakInside: "avoid" }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.companyName}</TableCell>
                        <TableCell>{row.serviceReqNumber}</TableCell>
                        <TableCell>{row.Total_Instrument}</TableCell>
                        <TableCell>{row.Calibrated}</TableCell>
                        <TableCell>{row.Approved}</TableCell>
                        <TableCell>{row.Pending}</TableCell>
                        <TableCell>{row.invoiceStatus}</TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <h6>
                      <b>Data Not Found</b>
                    </h6>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[
            10,
            30,
            100,
            { value: data.length, label: "All" },
          ]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </ListWrap>
  );
}

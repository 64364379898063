import { headerConfig } from "./certificateConfig";

export const printCertificateConfiguration = {
  pdfDoc: null,
  fontSize: 8,
  align: {
    left: "left",
    right: "right",
    center: "center",
  },
  fontSizes: {
    h1: 12,
    h2: 8.5,
    h3: 9,
    h4: 8,
    h5: 7,
    h6: 6,
    h7: 13,
    heleeven: 11,
  },
  textColor: "black",
  margin: 25,
  rowHeight: 20,
  pageHeight: 841,
  pageWidth: 595,
  lineHeight: 15,
  cellPadding: 5,
  cellPaddingTable:{ top: 2, left: 5, bottom: 2, right: 5 },
  lineWidth: 0.5,
  spaceConfig: {
    top: {
      S0: 0,
      S1: 1,
      S2: 2,
      S3: 3,
      S4: 4,
      S5: 5,
      S6: 6,
      S7: 7,
      S8: 8,
      S9: 9,
      S10: 10,
    },
    Bottom: {
      S0: 0,
      S1: 1,
      S2: 2,
      S3: 3,
      S4: 4,
      S5: 5,
      S6: 6,
      S7: 7,
      S8: 8,
      S9: 9,
      S10: 10,
    },
    locallineHeight: {
      S0: 0,
      S1: 1,
      S2: 2,
      S3: 3,
      S4: 4,
      S5: 5,
      S6: 6,
      S7: 7,
      S8: 8,
      S9: 9,
      S10: 10,
    },
  },
};

export const layoutConfig = {
  contentWidth:
    printCertificateConfiguration.pageWidth -
    2 * printCertificateConfiguration.margin,
  xPositionGlobal: printCertificateConfiguration.margin,
  yPositionGlobal:
    printCertificateConfiguration.margin +
    headerConfig.lettterImgHeight +
    headerConfig.headerTableHeight,
};
export const printcertificateConfigData = {
  certificateDetailsConfig: {},
  certificateTables: [],
  standardsConfig: [],
  readingRows: [],
  letterHeadAndElectronicAndDraftConfig: {},
  footerSignSettingConfig: {},
  certificateId: null,
};

export const parseValue = (value) => {
  value = value || "";
  value = value.replace("_unit_", "");
  value = value.replace("#", " ");
  return value;
};

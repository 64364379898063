
import {
  accuracyFormat,
  DateisValid,
  parseRange,
} from "../../../utils/components/accuracyandlcandrangesshow";

export let printSRFSSection = {
  firstpagelikeJRPM: false,
  lastpagelikeJRPM: false,
  dateofPerformancelikeJrpm: false,
  disciplineAndReadinTableStartNewpage: false,
  jrpmtable: false,
  readingTableSerialNoColumnShow: false,
};

const parseValue = (value) => {
  value = value || "";
  value = value.replace("_unit_", "");
  value = value.replace("|", " to ");
  value = value.replace("#", " ");
  return value;
};

export const srfDetailsData = (srfDetails) => {
  return {
    leftPanel: {
      keys: ["GST No", "Email", "SRF Number", "DC Number", "Entry Date"],
      values: [
        srfDetails?.companyData?.gstNumber || "",
        srfDetails?.clientArray?.emailId || "",
        srfDetails?.serviceReqNumber || "",
        srfDetails?.dcNumber || "",
        DateisValid(srfDetails?.entryDate)
      ],
    },
    rightPanel: {
      keys: [
        "Customer Name",
        "Phone Number",
        "Date of Receipt",
        "DC Date",
        "Calibration to be Completed on",
      ],
      values: [
        srfDetails?.clientArray?.contactPersonName || "",
        srfDetails?.clientArray?.contact || "",
        DateisValid(srfDetails?.receiptDate),
        DateisValid(srfDetails?.dcDate),
        DateisValid(srfDetails?.commitedDate),
      ],
    },
  };
};

// Exporting MasterEQPDetails
export const InstrumentDetails = (pushArray) => ({
  heading: "DETAILS OF STANDARD USED FOR CALIBRATION",
  headingFontSize: 9,
  headingBottomSpace: 0,
  headingTopSpace: 0,
  headingAlign: "center",
  spacebetweenHeadingandTable: 5,
  cellPadding: { top: 3, left: 3, bottom: 3, right: 3 },
  tableTextfontSize: 8,
  tableValueAlign: "center",
  keys: [
    "Sl.No.",
    "Description of the Equipment",
    "Make",
    "Model",
    "Serial Number",
    "Identification Number",
    "Range",
    "Calibration Points If any",
    "Declared Accuracy / Acceptance Criteria",
    "Requested Due Date",
    "Condition of UUC / Remarks",
  ],
  values: pushArray.map((item, index) => [
    index + 1,
    item.instrumentId || "",
    parseValue(item.make) || "",
    item.model || "",
    item.serialNumber || "",
    item.DUCID || "",
    parseRange(item.ranges) || "",
    item.calPoint || "",
    accuracyFormat(item.accuracy) || "",
    item.calFrequency || "",
    item.ConOfDuc || "",
    // DateisValid(item.validUpto),
  ]),
});

export const NameAndAddressDetails = (SRFSDetails) => {
  return {
    leftPanel: {
      keys: ["Client", "Address"],
      values: [
        SRFSDetails?.companyName || "",
        SRFSDetails?.newAddressArray?.address ||
          SRFSDetails?.newAddressArray?.[0]?.address ||
          "",
      ],
    },
    rightPanel: {
      keys: ["WO-PO No.", "Date"],
      values: [
        SRFSDetails?.srfsData?.poNumber || "",
        DateisValid(SRFSDetails?.srfsData?.poDate),
      ],
    },
    cellPadding: { top: 5, left: 5, bottom: 5, right: 5 },
    tableTextfontSize: 9,
  };
};

export const headerConfig = {
  headerCALIBRATIONSRFS: true,
  headerCALIBRATIONSRFSgap: 10,
  headerCALIBRATIONSRFSwithoutgap: 7,
  lettterImgHeight: 70,
  headerTableHeight: 15,
  headerandbodygap: 10,
  tableRowfontSizes: 10,
  cellPadding: { top: 4, left: 5, bottom: 4, right: 5 },
};

export const calculateHeader = {
  headerCALIBRATIONSRFSxpostion: headerConfig.headerCALIBRATIONSRFS
    ? headerConfig.lettterImgHeight + headerConfig.headerCALIBRATIONSRFSgap
    : headerConfig.lettterImgHeight +
      headerConfig.headerCALIBRATIONSRFSwithoutgap,
  headerCALIBRATIONSRFSxpostionafter:
    headerConfig.lettterImgHeight + headerConfig.headerCALIBRATIONSRFSgap + 10,
};

export const totalHeaderHeightWithCalibrationLableorNot = {
  total: headerConfig.headerCALIBRATIONSRFS
    ? headerConfig.lettterImgHeight + headerConfig.headerTableHeight + 20
    : headerConfig.lettterImgHeight + headerConfig.headerTableHeight + 7,
};

export const footerConfig = {
  footerimgRemove: true,
  footerimgHeight: 50,
  footertableHeight: 30,
  footerandbodygap: 35,
  endSRFSandimggap: 5,
  stampui: false,
};
let pageHeight = 841;
export const SRFSBorderLayout = {
  x: 15,
  y: totalHeaderHeightWithCalibrationLableorNot.total - 5,
  width: 565,
  height:
    pageHeight -
    footerConfig.footerimgHeight -
    footerConfig.footertableHeight -
    footerConfig.footerandbodygap -
    totalHeaderHeightWithCalibrationLableorNot.total +
    6,
  // height: 570
};

export const dynamicHeader = (SRFSDetailsConfig) => {
  return {
    leftPanel: {
      keys: ["SRF Number"],
      values: [SRFSDetailsConfig?.serviceReqNumber || ""],
    },
    rightPanel: {
      keys: ["Entry Date"],
      values: [DateisValid(SRFSDetailsConfig?.entryDate)],
    },
  };
};

export const tableConfiguration = {
  margins: { top: 0, right: 25, bottom: 0, left: 25 },
};

export const tableConfigurationheaderandfooter = {
  margins: { top: 0, right: 15, bottom: 0, left: 15 },
};

export const fontFamilyConfig = {
  useFont: "Aptos",
  bold: "bold",
  normal: "normal",
};

export const waterMarkImgConfig = {
  logoWidth: 250,
  logoHeight: 200,
};

export const environmentTableConfig = {
  spacebetweenHeadingandTable: 5,
  innerBorder: 0,
  cellPadding: { top: 2, left: 5, bottom: 2, right: 5 },
};

export const loadAndAddFont = async (
  pdfDoc,
  fontUrl,
  fontNameTTF,
  fontName,
  fontStyle
) => {
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const chunkSize = 8192; // Process in chunks for large buffers
    for (let i = 0; i < bytes.length; i += chunkSize) {
      binary += String.fromCharCode(...bytes.subarray(i, i + chunkSize));
    }
    return btoa(binary);
  };

  const fontResponse = await fetch(fontUrl);
  const fontBuffer = await fontResponse.arrayBuffer();
  const fontBase64 = arrayBufferToBase64(fontBuffer);

  pdfDoc.addFileToVFS(`${fontNameTTF}.ttf`, fontBase64);
  pdfDoc.addFont(`${fontNameTTF}.ttf`, fontName, fontStyle);
};

export const customFontFamily = {
  bold: "bold",
  normal: "normal",
  boldFont: "AptosBold",
  normalFont: "AptosNormal",
};


